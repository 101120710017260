.login-form {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.form-control-group {
  display: flex;
  flex-direction: column;
  min-width: 500px;
  margin-top: 12px;
}

.form-label {
  font-size: 14px;
}

.form-input {
  padding: 8px 12px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.23);
}

.form-submit-btn {
  margin-top: 25px;
}

.form-error {
  color: red;
}
